import React from 'react';
import { connect } from 'react-redux';
import { unitCosts } from '../../../../selectors';

import UnitCostsView from './UnitCostsView';

const UnitCostsRedux = ({ unitCosts, dates }) => {
  if (!unitCosts) return null;
  return <UnitCostsView unitCosts={unitCosts} dates={dates} />;
};

const mapStateToProps = state => ({
  unitCosts: unitCosts(state),
});

export default connect(mapStateToProps)(UnitCostsRedux);
