import API from '../api';
// *** REQUEST ***//
const getRequest = (url, props = {}) => API.get(url, props);
const postRequest = (url, data, props = {}) => API.post(url, data, props);
// *** REQUEST ***//

// *** URLS ***//
export const tasksUrl = '/api/tasks';
export const calculateUrl = task_id => `/api/tasks/${task_id}/calculate`;
export const pathsUrl = task_id => `/api/tasks/${task_id}/paths`;
export const facilitiesUrl = (task_id, type = 'year') =>
  `/api/tasks/${task_id}/facilities?agg_by=${type}`;
export const resultUrl = (task_id, type = 'year') =>
  `/api/tasks/${task_id}/result?agg_by=${type}`;
export const getExcelUrl = task_id => `/api/tasks/${task_id}/result/excel`;
export const getPdfUrl = task_id => `/api/tasks/${task_id}/result/pdf`;
export const getInitialFilesUrl = task_id => `/api/tasks/${task_id}/input_file`;
export const calculateFreightFromExel =
  '/api/calculate_freight_traffic_from_excel';
export const calculateFreightFromProjectIdUrl = project_id =>
  `/api/calculate_freight_traffic_from_iskra/${project_id}`;
export const getFileUrl = freight_traffic_id =>
  `/api/get_freight_traffic_result/${freight_traffic_id}`;
export const getExampleFreightTrafficDataUrl = `/api/get_example_freight_traffic_input_data`;
export const getExampleAppDataUrl = `/api/get_example_app_input_data`;
// *** URLS ***//

// *** SERVICES ***//
export const getPaths = taskId => getRequest(pathsUrl(taskId));
export const getResult = (taskId, sortBy) =>
  getRequest(resultUrl(taskId, sortBy));
export const sendFileService = (data, type) => {
  const props = {};
  let url = tasksUrl;
  if (type === 'cargo') {
    // props.responseType = 'blob';
    url = calculateFreightFromExel;
  }
  return postRequest(url, data, props);
};

export const calculateTask = taskId => getRequest(calculateUrl(taskId));
export const getFacilities = (taskId, sortBy) =>
  getRequest(facilitiesUrl(taskId, sortBy));
export const getExcel = taskId =>
  getRequest(getExcelUrl(taskId), {
    responseType: 'blob',
  });
export const getPdf = taskId =>
  getRequest(getPdfUrl(taskId), {
    responseType: 'blob',
  });
export const getInitialFiles = taskId =>
  getRequest(getInitialFilesUrl(taskId), {
    responseType: 'blob',
  });

export const getFile = freight_traffic_id =>
  getRequest(getFileUrl(freight_traffic_id), {
    responseType: 'blob',
  });

export const getExampleFreightTrafficData = () =>
  getRequest(getExampleFreightTrafficDataUrl, {
    responseType: 'blob',
  });

export const getExampleAppData = () =>
  getRequest(getExampleAppDataUrl, {
    responseType: 'blob',
  });

export const calculateFreightFromProjectId = project_id =>
  postRequest(calculateFreightFromProjectIdUrl(project_id));
// *** SERVICES ***//
