import React, { useEffect, useState } from 'react';
import { getActiveFacility } from '../../../../selectors';
import { setActiveFacility } from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { YMaps, Map } from 'react-yandex-maps';
import PlacemarkContainer from './Placemark';
import PolylineComponent from './PolylineComponent';
import classnames from 'classnames';
import MapButtons from './MapButtons';

export default ({ facilities, paths }) => {
  const activeFacility = useSelector(getActiveFacility);
  const dispatch = useDispatch();
  const [hideCrossroad, setHideCrossroad] = useState(true);
  const [hideFacilities, setHideFacilities] = useState(true);
  const [size, setSize] = useState({
    width: 660,
    height: 400,
  });
  const [instanceRef, setInstanceRef] = useState({
    geoObjects: {
      getBounds: () => null,
    },
  });
  const [plotData, setPlotData] = useState({
    placemarkData: [],
    pathsData: [],
    mapData: {
      controls: [],
      center: [55.751574, 37.573856],
      behaviors: [],
      yandexMapDisablePoiInteractivity: true,
      suppressMapOpenBlock: true,
      avoidFractionalZoom: false,
      zoom: 9,
    },
  });
  useEffect(() => {
    const placemarkData = Object.keys(facilities).map(key => {
      const {
        type,
        point: { coordinates },
        name,
        id,
      } = facilities[key];
      return {
        id,
        name,
        type,
        coordinates: [coordinates[1], coordinates[0]],
      };
    });
    const pathsData = paths.map(
      ({ class_name, first_facility_id, last_facility_id, id, type }) => {
        const firstFacilitiesPoints =
          facilities[first_facility_id].point.coordinates;
        const lastFacilitiesPoints =
          facilities[last_facility_id].point.coordinates;
        const geometry = [
          [firstFacilitiesPoints[1], firstFacilitiesPoints[0]],
          [lastFacilitiesPoints[1], lastFacilitiesPoints[0]],
        ];
        return { class_name, type, geometry, id };
      }
    );
    setPlotData({
      mapData: {
        controls: [],
        center: placemarkData[0].coordinates,
        zoom: 9,
      },
      placemarkData,
      pathsData,
    });
  }, [facilities, paths]);
  const setMapCenter = () => {
    instanceRef.setBounds(instanceRef.geoObjects.getBounds());
  };
  const resizeHandler = () => {
    if (size.width > 660) {
      setSize({
        width: 660,
        height: 400,
      });
      dispatch({ type: 'FULL_SCREEN', payload: false });
    } else {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setSize({
        width,
        height,
      });
      dispatch({ type: 'FULL_SCREEN', payload: true });
    }
  };
  return (
    <div
      className={classnames('graph map-container', {
        hideCrossroad,
        hideFacilities,
        active: size.width > 660,
      })}
      style={{
        width: size.width > 660 ? 'auto' : 660,
      }}
    >
      <MapButtons resizeHandler={resizeHandler} setMapCenter={setMapCenter} />
      <div className={classnames('map-sub-container')}>
        <YMaps>
          <Map
            width={size.width}
            height={size.height}
            defaultState={plotData.mapData}
            instanceRef={ref => {
              setInstanceRef(ref);
            }}
            onClick={() => {
              if (activeFacility.type) {
                dispatch(setActiveFacility({}));
              }
            }}
            onBoundschange={e => {
              const newZoom = e.get('newZoom');
              if (newZoom >= 12) {
                if (hideCrossroad) {
                  setHideCrossroad(false);
                }
              } else {
                if (!hideCrossroad) {
                  setHideCrossroad(true);
                }
              }
              if (newZoom >= 9) {
                if (hideFacilities) {
                  setHideFacilities(false);
                }
              } else {
                if (!hideFacilities) {
                  setHideFacilities(true);
                }
              }
            }}
            defaultOptions={{
              avoidFractionalZoom: false,
              suppressMapOpenBlock: true,
              yandexMapDisablePoiInteractivity: true,
            }}
          >
            <PolylineComponent pathsData={plotData.pathsData} />
            <PlacemarkContainer
              mapRef={instanceRef}
              placemarkData={plotData.placemarkData}
            />
          </Map>
        </YMaps>
      </div>
    </div>
  );
};
