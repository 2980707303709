import React from 'react';
import { useSelector } from 'react-redux';
import { getWarnings } from '../../../selectors';
import TransportDemands from './TransportDemands';
import UnitCosts from './UnitCosts';
import Charge from './Charge';
import Cargo from './Cargo';
import Loader from '../../components/Loader';
import classnames from 'classnames';
import './style.scss';

export default function BottomRowView({ dates }) {
  const warnings = useSelector(getWarnings);
  if (warnings) return null;
  let size = '';
  if (dates) {
    size = dates.length < 5 ? 'small' : 'big';
  }
  return (
    <div
      className={classnames('dashboard-card bottom', { loading: !dates }, [
        size,
      ])}
    >
      {!dates && (
        <Loader secondTitle='Расчёт может занимать продолжительное время.' />
      )}
      {dates && (
        <>
          <div className='bottom-row'>
            <div>
              <TransportDemands dates={dates} />
              <Charge dates={dates} />
            </div>
            <div className='clearfix' />
          </div>
          <div className='bottom-row'>
            <div>
              <Cargo dates={dates} />
              <UnitCosts dates={dates} />
            </div>
            <div className='clearfix' />
          </div>
        </>
      )}
    </div>
  );
}
