import crossroad from '../../../../../assets/images/crossroad.svg';
import crossroadHover from '../../../../../assets/images/crossroadHover.svg';
import crossroadInactive from '../../../../../assets/images/crossroadInactive.svg';
import consumer from '../../../../../assets/images/consumer.svg';
import consumerHover from '../../../../../assets/images/consumerHover.svg';
import consumerInactive from '../../../../../assets/images/consumerInactive.svg';
import activeConsumer from '../../../../../assets/images/activeConsumer.svg';
import activeProducer from '../../../../../assets/images/activeProducer.svg';
import storage from '../../../../../assets/images/storage.svg';
import producer from '../../../../../assets/images/producer.svg';
import producerHover from '../../../../../assets/images/producerHover.svg';
import producerInactive from '../../../../../assets/images/producerInactive.svg';
import storageInactive from '../../../../../assets/images/storageInactive.svg';

const hoverObj = {
  crossroadHover,
  consumerHover,
  producerHover,
};

const unHoverObj = {
  crossroad,
  consumer,
  producer,
};

const config = {
  crossroad: {
    iconImageSize: [28, 28],
    iconImageOffset: [-14, -14],
    iconImageHref: crossroad,
  },
  activeProducer: {
    iconImageSize: [44, 56],
    iconImageOffset: [-22, -44],
    iconImageHref: activeProducer,
  },
  producer: {
    iconImageSize: [44, 44],
    iconImageOffset: [-22, -22],
    iconImageHref: producer,
  },
  activeConsumer: {
    iconImageSize: [44, 56],
    iconImageOffset: [-22, -44],
    iconImageHref: activeConsumer,
  },
  consumer: {
    iconImageSize: [44, 44],
    iconImageOffset: [-22, -22],
    iconImageHref: consumer,
  },
  storage: {
    iconImageSize: [44, 44],
    iconImageOffset: [-22, -22],
    iconImageHref: storage,
  },
};

export const unsetHover = type => {
  return unHoverObj[type];
};
export const setHover = type => {
  return hoverObj[`${type}Hover`];
};

export default (type, notActive, active) => {
  let icon = Object.assign({}, config[type]);
  if (notActive) {
    if (type === 'producer') {
      icon.iconImageHref = producerInactive;
    } else if (type === 'crossroad') {
      icon.iconImageHref = crossroadInactive;
    } else if (type === 'consumer') {
      icon.iconImageHref = consumerInactive;
    } else {
      icon.iconImageHref = storageInactive;
    }
  }
  if (active) {
    if (type === 'producer') {
      return config.activeProducer;
    } else {
      return config.activeConsumer;
    }
  }
  return icon;
};
