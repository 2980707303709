import {
  SET_PATHS,
  CALCULATED,
  SET_TASK_ID,
  SET_RESULTS,
  CLEAR_STATE,
  SET_WARNING,
  SET_FACILITIES,
  SORT_BY,
  SET_ACTIVE_FACILITY,
} from './types';

import {
  getPdf,
  getPaths,
  getExcel,
  getResult,
  getFacilities,
  calculateTask,
  sendFileService,
  getInitialFiles,
  calculateFreightFromProjectId,
} from './service';

export const dispatchObj = (type, payload) => ({
  type,
  payload,
});

export const clearState = { type: CLEAR_STATE };
export const setTaskId = id => dispatchObj(SET_TASK_ID, id);
export const setWarning = data => dispatchObj(SET_WARNING, data);
export const setSortBy = data => dispatchObj(SORT_BY, data);
export const setActiveFacility = data => dispatchObj(SET_ACTIVE_FACILITY, data);

export const getFile = (type, taskId) => {
  if (type === 'pdf') {
    return getPdf(taskId);
  } else if (type === 'excel') {
    return getExcel(taskId);
  } else {
    return getInitialFiles(taskId);
  }
};
export const calculateProject = projectNumber => {
  return dispatch => {
    return calculateFreightFromProjectId(projectNumber).then(
      res => {
        const {
          data: { data },
        } = res;
        dispatch(setTaskId(`cargo/${data[0]}`));
        return data[0];
      },
      err => {
        if (err.response.status === 400) {
          dispatch(setWarning(err.response.data.issues));
          throw err;
        } else {
          dispatch(setWarning(err.response.status));
          throw err;
        }
      }
    );
  };
};
export const sendFile = (formData, type = '') => {
  return dispatch => {
    return sendFileService(formData, type)
      .then(
        res => {
          const {
            data: { data },
          } = res;
          return data[0];
        },
        err => {
          if (err.response.status === 400) {
            dispatch(setWarning(err.response.data.issues));
            throw err;
          } else {
            dispatch(setWarning(err.response.status));
            throw err;
          }
        }
      )
      .then(taskId => {
        if (type === 'cargo') {
          dispatch(setTaskId(`cargo/${taskId}`));
          return taskId;
        } else {
          dispatch(setTaskId(taskId));
          return calculateTask(taskId).then(
            () => taskId,
            err => {
              if (err.response.status === 504) {
                getResultFunction(taskId, dispatch);
                throw new Error('Waiting response');
              } else {
                dispatch(setWarning('infeasible'));
                throw err;
              }
            }
          );
        }
      })
      .then(id => {
        if (type === 'cargo') {
          return true;
        }
        return getResult(id)
          .then(({ data: { data } }) => {
            dispatch(dispatchObj(SET_RESULTS, data));
          })
          .catch(err => {
            dispatch(setWarning(err.response.status));
            throw err;
          });
      })
      .then(() => dispatch({ type: CALCULATED }));
  };
};

export const getCommonObjects = (taskId, sortBy, dispatch) => {
  getPaths(taskId)
    .then(({ data: { data } }) => dispatch(dispatchObj(SET_PATHS, data)))
    .catch(err => {
      console.log(err);
      return dispatch(setWarning(err.response.status));
    });

  getFacilities(taskId, sortBy)
    .then(({ data: { data } }) => dispatch(dispatchObj(SET_FACILITIES, data)))
    .catch(err => {
      console.log(err, 'response');
      return dispatch(setWarning(err.response.status));
    });
};

const sortByPercentage = (data, type) => {
  let obj = {};
  if (data[type]) {
    const keys = Object.keys(data[type]);
    keys.forEach(key => {
      const resources = Object.assign({}, data[type][key].resources);
      const resourcesKeys = Object.keys(data[type][key].resources);
      resourcesKeys.forEach(key => {
        resources[key].sort((a, b) => {
          return b.percentage - a.percentage;
        });
      });
      obj[key] = {
        ...data[type][key],
        resources,
      };
    });
    return obj;
  }
};

export const getResultFunction = (id, sortBy, dispatch) => {
  return getResult(id, sortBy)
    .then(({ data: { data = [] } }) => {
      let producers_info = {};
      let consumers_info = {};
      if (data[0]) {
        producers_info = sortByPercentage(data[0], 'producers_info');
        consumers_info = sortByPercentage(data[0], 'consumers_info');
      }
      dispatch(
        dispatchObj(SET_RESULTS, [
          { ...data[0], producers_info, consumers_info },
        ])
      );
    })
    .catch(({ response }) => {
      if (response.status === 404) {
        const type = response.data.issues[0].reason;
        if (type === 'calculating') {
          setTimeout(() => {
            getResultFunction(id, 'year', dispatch);
          }, 30000);
        } else if ('infeasible') {
          dispatch(setWarning('infeasible'));
        } else {
          dispatch(setWarning(500));
        }
      } else {
        dispatch(setWarning(500));
      }
    });
};

export const getInfo = (id, sortBy = 'year') => {
  return dispatch => {
    getCommonObjects(id, sortBy, dispatch);
    getResultFunction(id, sortBy, dispatch);
  };
};
