import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dates, getErrors, taskId } from '../../../selectors';
import { clearState, getFile } from '../../../actions';
import HeaderButtonsView from './HeaderButtonsView';
import _isEqual from 'lodash.isequal';
import { saveFile } from '../../../utils';

class HeaderButtons extends React.Component {
  state = { pdf: true, excel: true, initialFiles: false };
  componentDidUpdate(prevProps) {
    const { dates, error } = this.props;
    if (
      !_isEqual(dates, prevProps.dates) ||
      !_isEqual(error, prevProps.error)
    ) {
      if (!dates || error) {
        this.setState({
          pdf: true,
          excel: true,
          initialFiles: true,
        });
      } else {
        this.setState({
          pdf: false,
          excel: false,
          initialFiles: false,
        });
      }
    }
  }
  removeHandler = () => {
    this.props.clearState();
    this.props.history.replace('/');
  };
  clickHandler = type => {
    const { taskId } = this.props;
    if (type === 'remove') {
      this.removeHandlerremoveHandler();
    } else {
      this.setState(prev => ({ ...prev, [type]: true }));
      this.props
        .getFile(type, taskId)
        .then(response => {
          saveFile(response, () => {
            this.setState(prev => ({ ...prev, [type]: false }));
          });
        })
        .catch(err => console.log(err));
    }
  };
  render() {
    const { history } = this.props;
    if (!history.location.pathname.includes('dashboard')) return null;
    return (
      <HeaderButtonsView
        clickHandler={this.clickHandler}
        initialFilesDisabled={this.state.initialFiles}
        pdfDisabled={this.state.pdf}
        excelDisabled={this.state.excel}
        cargo={history.location.pathname.includes('cargo')}
      />
    );
  }
}

const mapStateToProps = state => ({
  dates: dates(state),
  error: getErrors(state),
  taskId: taskId(state),
});

const mapDispatchToProps = dispatch => ({
  clearState: () => dispatch(clearState),
  getFile: (type, taskId) => getFile(type, taskId),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderButtons));
