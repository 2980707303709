import React from 'react';
import { connect } from 'react-redux';
import { charge } from '../../../../selectors';

import ChargeView from './ChargeView';

const ChargeRedux = ({ charge, dates }) => {
  if (!charge) return null;
  return <ChargeView charge={charge} dates={dates} />;
};

const mapStateToProps = state => ({
  charge: charge(state),
});

export default connect(mapStateToProps)(ChargeRedux);
