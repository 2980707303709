import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import StartPage from './StartPage';
import CommonContainer from './CommonContainer';
import Cargo from './Dashboard/Cargo';
import Auth from './Auth';

export default function AppContainer() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path='/auth' component={Auth} />
        <CommonContainer>
          <Switch>
            <Route exact path='/' component={StartPage} />
            <Switch>
              <Route exact path='/dashboard/:id' component={Dashboard} />
              <Route exact path='/dashboard/:id/:params' component={Cargo} />
            </Switch>
          </Switch>
        </CommonContainer>
      </Switch>
    </HashRouter>
  );
}
