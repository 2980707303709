import React from 'react';
import classnames from 'classnames';
import ScrollArea from 'react-scrollbar/dist/no-css';

const DropdownComponent = ({
  options,
  onChangeHandler,
  activeValue,
  setActive,
}) => {
  const ref = React.useRef();
  React.useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);
  const onBlurHandler = e => {
    if (!e.nativeEvent.relatedTarget) {
      setActive(false);
    }
  };
  const height = options.length * 34 > 180 ? 180 : options.length * 34;
  return (
    <div
      ref={ref}
      tabIndex={0}
      onBlur={onBlurHandler}
      className='dropdown-menu'
      style={{ height }}
    >
      <ScrollArea speed={1}>
        {options.map((el, index) => {
          return (
            <h3
              key={index}
              onClick={() => onChangeHandler(index)}
              className={classnames('dropdown-option', {
                'is-selected': el.value === activeValue.value,
              })}
            >
              {el.label}
            </h3>
          );
        })}
      </ScrollArea>
    </div>
  );
};

export default DropdownComponent;
