import React from 'react';
import { connect } from 'react-redux';
import { cargo } from '../../../../selectors';

import CargoView from './CargoView';

const CargoRedux = ({ cargo, dates }) => {
  if (!cargo) return null;
  return <CargoView dates={dates} cargo={cargo} />;
};

const mapStateToProps = state => ({
  cargo: cargo(state),
});

export default connect(mapStateToProps)(CargoRedux);
