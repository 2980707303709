import React from 'react';

export default ({loaderText = 'Идет расчёт...', secondTitle}) => {
    return <div className='loader-container'>
        <div className='cssload-loader'>
            <div className="cssload-inner cssload-one"/>
            <div className="cssload-inner cssload-two"/>
            <div className="cssload-inner cssload-three"/>
        </div>
        {
            loaderText && <h2 className="loader-text">{loaderText}</h2>
        }
        {
            secondTitle && <h2 className="loader-second-text">{secondTitle}</h2>
        }
    </div>
}