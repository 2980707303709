import React from 'react';
import DropdownContainer from './DropdownContainer';

class Dropdown extends React.Component {
  state = {
    activeValue: {},
  };
  componentDidMount() {
    this.setState({
      activeValue: this.props.dropdownValue[0],
    });
  }
  componentWillUpdate(prevProps) {
    if (prevProps.dropdownValue.length !== this.props.dropdownValue.length) {
      let activeValue = this.props.dropdownValue[0];
      console.log('componentWillUpdate');
      if (this.props.defaultValue) {
        activeValue = this.props.defaultValue;
      }
      this.setState({
        activeValue,
      });
    }
  }
  onChangeHandler = e => {
    const { changeActiveValue } = this.props;
    this.setState({ activeValue: e });
    if (typeof changeActiveValue === 'function') {
      changeActiveValue(e);
    }
  };
  render() {
    const { classContainer, dropdownValue } = this.props;
    if (dropdownValue.length === 1) {
      return (
        <h2 className='dropdown-title-single'>{dropdownValue[0].label}</h2>
      );
    }
    return (
      <>
        <DropdownContainer
          defaultValue={this.props.defaultValue}
          activeValue={this.state.activeValue}
          options={dropdownValue}
          onChange={this.onChangeHandler}
          classContainer={classContainer}
        />
      </>
    );
  }
}

export default Dropdown;

/*
 * dropdownValue = [
 * {value: 1, label: 'text'}
 * ]
 * */
