import React from 'react';
import tableType from './tableTypeConfig';
import TableComponent from './TableComponent';

const ActiveFacilityTable = ({ type, resources, title }) => {
  return (
    <table className={type}>
      <thead>
        <tr className='active-facility-table-title'>
          {type === 'producer' && <th className='producer-title'>{title}</th>}
          {type === 'consumer' && <th></th>}
          {tableType[type].thead.map((el, key) => {
            return <th key={key}>{el}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {resources[title].map((field, rowIndex) => {
          return (
            <tr key={rowIndex} className='active-facility-table-row'>
              {tableType[type].tbody.map((el, key) => {
                return (
                  <TableComponent
                    index={key}
                    rowIndex={rowIndex % 10}
                    key={key}
                    type={type}
                    field={field}
                    el={el}
                  />
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ActiveFacilityTable;
