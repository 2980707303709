import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { dates } from '../../selectors';

const BottomRowRedux = EnhancedComponent => props => (
  <EnhancedComponent {...props} />
);

const mapStateToProps = state => ({
  dates: dates(state),
});

export default compose(connect(mapStateToProps), BottomRowRedux);
