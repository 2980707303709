import React from 'react';
import classnames from 'classnames';
import DropdownComponent from './DropdownComponent';

const DropdownContainer = ({
  activeValue,
  options = [],
  onChange,
  classContainer,
  defaultValue,
}) => {
  const [active, setActive] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const optionsObject = options.reduce((acc, val) => {
    acc = {
      ...acc,
      [val.value]: val.label,
    };
    return acc;
  }, {});
  React.useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
      setTitle(defaultValue.value);
    }
  }, [defaultValue, onChange]);
  const onClickHandler = () => {
    setActive(!active);
  };
  const onChangeHandler = index => {
    setActive(false);
    onChange(options[index]);
  };

  React.useEffect(() => {
    if (![undefined, null].includes(activeValue.value)) {
      if (optionsObject[activeValue.value] !== title) {
        setTitle(optionsObject[activeValue.value]);
      }
    }
  }, [activeValue.value, optionsObject, title]);
  return (
    <div
      className={classnames('dropdown-root', [classContainer], {
        active,
      })}
    >
      <div onClick={onClickHandler}>
        <h3 className='dropdown-title'>{title}</h3>
        <div className='dropdown-arrow'></div>
      </div>
      {active && (
        <DropdownComponent
          options={options}
          activeValue={activeValue}
          onChangeHandler={onChangeHandler}
          setActive={setActive}
        />
      )}
    </div>
  );
};

export default DropdownContainer;
