import React from 'react';
import './auth.scss';

export default function Auth(props) {
  const [fields, setFields] = React.useState({
    name: '',
    password: '',
  });
  const onSubmitHandler = e => {
    e.preventDefault();
    if (fields.name === 'user' && fields.password === 'gpn2018') {
      localStorage.setItem('auth', true);
      props.history.replace('/');
    }
  };
  const onChangeHandler = ({ target: { name, value } }) => {
    setFields({ ...fields, [name]: value });
  };
  return (
    <div className='auth-container'>
      <div className='auth-subcontainer'>
        <h1 className='auth-title'>logistics</h1>
        <form onSubmit={onSubmitHandler}>
          <div className='auth-fields'>
            <label htmlFor='name' className='auth-label'>
              <span className='auth-label-text'>Имя пользователя</span>
              <input
                name='name'
                type='text'
                value={fields.name}
                className='auth-input'
                onChange={onChangeHandler}
              />
            </label>
            <label htmlFor='password' className='auth-label'>
              <span className='auth-label-text'>Пароль</span>
              <input
                name='password'
                type='password'
                onChange={onChangeHandler}
                value={fields.password}
                className='auth-input'
              />
            </label>
          </div>
          <button className='main-button auth-button'>Войти</button>
        </form>
      </div>
    </div>
  );
}
