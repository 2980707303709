import React from 'react';

const TableTopRow = ({ resources, title }) => {
  return (
    <>
      <h3 className='active-facility-title'>{title}</h3>
      <div className='table-color-line'>
        {resources[title].map((el, key) => {
          const width = `calc(${el.percentage}% - 2px)`;
          return (
            <div
              key={key}
              className={`active-facility-percentage-line-color c-${key}`}
              style={{ width }}
            />
          );
        })}
      </div>
    </>
  );
};

export default TableTopRow;
