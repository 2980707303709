const config = {
  winter_roads: {
    strokeColor: 'rgba(21, 182, 234, 0.32)',
    strokeWidth: 3,
  },
  water: {
    strokeColor: '#019DDC',
    strokeWidth: 3,
  },
  air: {
    strokeColor: '#583F83',
    strokeWidth: 1,
  },
  roads: {
    strokeColor: '#FFA129',
    strokeWidth: 3,
  },
  railway: {
    strokeColor: '#6F7A98',
    strokeWidth: 3,
    strokeStyle: '2 1.66666667',
  },
};

export default (type, notActive) => {
  const palette = Object.assign(
    {},
    config[type] || {
      strokeColor: '#FF69B4',
      strokeWidth: 3,
    }
  );
  if (notActive) {
    palette.strokeColor = '#CCCCCC';
  }
  return palette;
};
