import React from 'react';
import { useDispatch } from 'react-redux';
import { clearState } from '../actions';
import { withRouter } from 'react-router-dom';
import HeaderButtons from './components/HeaderButtons';
import Errors from './components/Errors';
import './common.scss';

const CommonContainer = ({ children, history }) => {
  const [subTitle, setSubTitle] = React.useState('');
  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(clearState);
    history.replace('/');
  };
  React.useEffect(() => {
    if (history.location.pathname.includes('dashboard')) {
      if (history.location.pathname.includes('cargo')) {
        setSubTitle('Расчет грузопотока');
      } else {
        setSubTitle('Расчет логистического варианта');
      }
    } else {
      setSubTitle('');
    }
    if (!localStorage.getItem('auth')) {
      history.replace('/auth');
    }
  }, [history.location.pathname]);
  return (
    <div className='app'>
      <div className='wrapper'>
        <div className='header'>
          <span className='cursor-pointer'>
            <div onClick={onClickHandler}>
              <h1 className='main-title'>logistics</h1>
            </div>
          </span>
          <span className='sub-title'>{subTitle}</span>
          <HeaderButtons />
          <div className='clearfix' />
        </div>
        {children}
        <Errors />
      </div>
    </div>
  );
};

export default withRouter(CommonContainer);
