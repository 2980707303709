import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLoadingLevels, dates } from '../../../selectors';
import DropDown from '../../components/Dropdown';
import Table from './Table';
import dict from './utils/dict';

export default function TableContainer() {
  const [types, setTypes] = useState({
    data: {},
    active: '',
    dropDownValues: [],
  });
  const loadingLevels = useSelector(getLoadingLevels);
  const tableDates = useSelector(dates);
  useEffect(() => {
    if (loadingLevels) {
      setTypes({
        data: loadingLevels,
        active: Object.keys(loadingLevels)[0],
        dropDownValues: Object.keys(loadingLevels).map(el => ({
          value: el,
          label: dict[el],
        })),
      });
    }
  }, [loadingLevels]);
  const changeActiveValueHandler = ({ value }) => {
    setTypes({
      ...types,
      active: value,
    });
  };
  if (!loadingLevels || !types.active) return null;
  const data = types.data[types.active];
  const dataArr = Object.keys(data).map(key => ({
    key,
    values: data[key].map(el => el.toFixed(2) * 1),
  }));
  return (
    <div className='dashboard-card bottom'>
      <div className='no-wrap table-top-row'>
        <h2 className='graph__title'>Максимальная годовая загрузка, %</h2>
        <DropDown
          defaultValue={types.dropDownValues[0]}
          dropdownValue={types.dropDownValues}
          changeActiveValue={changeActiveValueHandler}
        />
        <div className='clearfix' />
      </div>
      <Table data={dataArr} tableDates={tableDates} />
    </div>
  );
}
