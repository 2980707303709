import React from 'react';
import HelpIcon from '../../../components/HelpIcon';
import CommonBarChart from '../../CommonBarChart';

const titles = ['CAPEX', 'OPEX'];
const dataKeys = ['capex', 'opex'];
const colors = ['#0079C1', '#23D692'];

export default ({ charge: { capex, opex }, dates }) => {
  let legendData = [];
  const cond = capex.some(el => el !== 0) || opex.some(el => el !== 0);
  const chartData = dates.map((date, index) => {
    const obj = { name: date };
    if (cond) {
      obj.opex = opex[index] / 1000000000;
      obj.capex = capex[index] / 1000000000;
    }
    return obj;
  });
  if (cond) {
    legendData = titles.map((title, index) => ({
      title,
      color: colors[index],
    }));
  }
  return (
    <CommonBarChart
      stackId='asa2x'
      colors={colors}
      showSplash={!cond}
      dataKeys={dataKeys}
      chartData={chartData}
      legendData={legendData}
      title={
        <>
          <span className='no-wrap'>
            Общие затраты, млрд руб.{' '}
            {dates.length < 5 && <HelpIcon legendData={legendData} />}
          </span>
        </>
      }
    />
  );
};
