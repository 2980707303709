import React from 'react';
import { connect } from 'react-redux';
import { transportDemands } from '../../../../selectors';

import TransportDemandsView from './TransportDemandsView';

const TransportDemandsRedux = ({ dates, transportDemands }) => {
  if (!transportDemands) return null;
  return (
    <TransportDemandsView dates={dates} transportDemands={transportDemands} />
  );
};

const mapStateToProps = state => ({
  transportDemands: transportDemands(state),
});

export default connect(mapStateToProps)(TransportDemandsRedux);
