import React from 'react';
import Map from './Map';
import ConsumptionVolumes from './ConsumptionVolumes';

export default () => {
  return (
    <div className='dashboard-card dashboard-card__top-row'>
      <div>
        <Map />
        <ConsumptionVolumes />
      </div>
      <div className='clearfix' />
    </div>
  );
};
