import React from 'react';
import { Line } from 'recharts';
import Graph from '../../Graph';
import HelpIcon from '../../../components/HelpIcon';
import dict from './utils/dict';
import coloring from './utils/coloring';

const dotStyle = { r: 4, strokeWidth: 2 };

export default ({ cargo, dates }) => {
  const keys = Object.keys(cargo);
  const chartData = dates.map((date, index) => {
    const data = { name: date };
    keys.forEach(key => {
      data[key] = cargo[key][index] / 1000;
    });
    return data;
  });

  const legendData = keys.map(key => ({
    title: dict[key],
    color: coloring[key].stroke,
  }));
  const activeDotStyle = key => ({
    r: 6,
    stroke: coloring[key].stroke,
    strokeWidth: 5,
    strokeOpacity: 0.16,
    fill: 'transparent',
  });
  let small = false;
  if (dates) {
    small = dates.length < 5 ? true : false;
  }
  return (
    <Graph
      small={small}
      data={chartData}
      type='LineChart'
      legendData={legendData}
      showSplash={!keys.length}
      title={
        <>
          Грузопоток{' '}
          <span className='no-wrap'>
            по каналам поставки, тыс. тонн{' '}
            {small && <HelpIcon legendData={legendData} />}
          </span>
        </>
      }
    >
      {chartData.length &&
        keys.map(key => {
          return (
            <Line
              key={key}
              dataKey={key}
              type='monotone'
              {...coloring[key]}
              dot={dotStyle}
              activeDot={activeDotStyle(key)}
            />
          );
        })}
    </Graph>
  );
};
