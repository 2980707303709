import React from 'react';
import { useSelector } from 'react-redux';
import { getActiveFacility } from '../../../../selectors';
import classnames from 'classnames';

const MapButtons = ({ setMapCenter, resizeHandler }) => {
  const { type } = useSelector(getActiveFacility);
  return (
    <div
      className={classnames('map-button-container', {
        activeFacility: !!type,
      })}
    >
      <button onClick={setMapCenter} className='to-center' />
      <button onClick={resizeHandler} className='resize-map' />
    </div>
  );
};

export default MapButtons;
