import React, { useCallback } from 'react';
import { Placemark } from 'react-yandex-maps';
import iconImageHrefs, { setHover, unsetHover } from '../utils/iconImageHrefs';
import Dict from '../utils/Dict';

const modules = [
  'geoObject.addon.balloon',
  'geoObject.addon.hint',
  'layout.ImageWithContent',
];

const PlacemarkComponent = ({
  placemarkData,
  mapRef,
  id,
  type,
  notActive,
  coordinates,
  index,
  name,
  active,
  clickHandler,
}) => {
  const setMapCenter = useCallback(() => {
    mapRef.setBounds(mapRef.geoObjects.getBounds());
  }, [mapRef]);
  const onClickHandler = () => clickHandler(id, type, name);
  let hintContent = Dict[type];
  const properties = {
    iconContent: `<div class="icon-label-container ${type}"><span class="icon-label">${name}</span></div>`,
    hintContent: `<div class="h-content"><span class="h-content__text">${hintContent}</span></div>`,
  };
  let zIndex = -1;
  if (type !== 'crossroad') {
    zIndex = 1;
    if (notActive) {
      zIndex = 0;
    }
  }
  if (active) {
    zIndex = 2;
  }
  const options = {
    zIndex,
    iconLayout: 'default#imageWithContent',
    ...iconImageHrefs(type, notActive, active),
  };
  return (
    <Placemark
      key={id}
      onLoad={() => {
        if (index === placemarkData.length - 1) {
          setMapCenter();
        }
      }}
      onMouseEnter={e => {
        if (!active && !notActive) {
          const icon = setHover(type);
          if (icon) {
            e.get('target').options.set('iconImageHref', icon);
          }
        }
      }}
      onMouseLeave={e => {
        if (!active && !notActive) {
          const icon = unsetHover(type);
          if (icon) {
            e.get('target').options.set('iconImageHref', icon);
          }
        }
      }}
      zIndex={zIndex}
      options={options}
      modules={modules}
      properties={properties}
      onClick={onClickHandler}
      geometry={coordinates}
    />
  );
};

export default PlacemarkComponent;
