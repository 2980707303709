import React from 'react';
import DescriptionTitle from './DescriptionTitle';

export default function DescriptionContainer({ type, warnings }) {
  if (type === 'error') {
    return (
      <h3 className='alert-desc'>
        Обратитесь к разработчикам для устранения ошибки.
      </h3>
    );
  }
  if (warnings === 'infeasible') {
    return <h3 className='alert-desc'>Задача не имеет решения.</h3>;
  }
  return (
    <div>
      {warnings.map((warning, key) => (
        <div key={key} className='description-container'>
          <DescriptionTitle warnings={warning} />
        </div>
      ))}
    </div>
  );
}
