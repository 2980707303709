import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { taskId } from '../../selectors';
import { clearState, setTaskId, getInfo } from '../../actions';
import { withRouter } from 'react-router-dom';
import DashboardView from './DashboardView';

const DashboardContainer = ({
  taskId,
  getInfo,
  clearState,
  setTaskId,
  match,
}) => {
  useEffect(() => {
    if (taskId !== match.params.id) {
      clearState();
      setTaskId(match.params.id);
      getInfo(match.params.id);
    }
  }, [match.params.id, clearState, taskId, getInfo, setTaskId]);
  return <DashboardView />;
};

const mapStateToProps = state => ({
  taskId: taskId(state),
});

const mapDispatchToProps = dispatch => ({
  clearState: () => dispatch(clearState),
  setTaskId: id => dispatch(setTaskId(id)),
  getInfo: id => dispatch(getInfo(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardContainer));
