import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');
const toObject = (xs, key) => {
  if (!xs) return {};
  return xs.reduce((rv, x) => {
    rv[x[key]] = x;
    return rv;
  }, {});
};

export const results = state => state.dashboard.results[0];
export const getPaths = state => state.dashboard.paths;
export const getSortBy = state => state.dashboard.sortBy;
export const getFacilities = state => state.dashboard.facilities;
export const getErrors = state => state.dashboard.errors;
export const getWarnings = state => state.dashboard.warnings;
export const getActiveFacility = state => state.dashboard.activeFacility;
export const getFullScreen = state => state.dashboard.fullScreen;
export const taskId = state => state.dashboard.taskId;
export const paths = createSelector(getPaths, paths => paths);
export const charge = createSelector(results, ({ charge }) => charge);

export const getActiveFacilityData = state => {
  const {
    activeFacility: { type, id },
  } = state.dashboard;
  if (type === 'producer') {
    const { producers_info } = state.dashboard.results[0];
    if (!producers_info || !producers_info[id]) return {};
    return producers_info[id];
  } else {
    const { consumers_info } = state.dashboard.results[0];
    if (!consumers_info || !consumers_info[id]) return {};
    return consumers_info[id];
  }
};

export const getActiveFacilities = createSelector(
  [getActiveFacilityData],
  activeFacilityData => {
    if (activeFacilityData.resources) {
      const keys = Object.keys(activeFacilityData.resources);
      const ids = [];
      keys.forEach(key => {
        activeFacilityData.resources[key].forEach(el => {
          ids.push(el.producer_id || el.consumer_id);
        });
      });
      return ids;
    }
    return false;
  }
);

export const facilities = createSelector(getFacilities, facilities =>
  toObject(facilities, 'id')
);

export const dates = createSelector(
  [results, getSortBy],
  ({ dates }, sortBy) => {
    const formatType = sortBy === 'year' ? 'YYYY' : 'MMMM';
    return dates && dates.map(date => dayjs(date).format(formatType));
  }
);

export const getYear = createSelector([results, getSortBy], ({ dates }) => {
  return dates && dayjs(dates[0]).format('YYYY');
});

export const allConsumptionVolumes = createSelector(
  [getFacilities, getSortBy],
  (facilities, sortBy) => {
    const formatType = sortBy === 'year' ? 'YYYY' : 'MMMM';
    const dates = facilities
      .filter(({ consumption }) => consumption)
      .map(({ consumption }) => {
        return {
          dates: consumption.dates.map(date => dayjs(date).format(formatType)),
          volumes: consumption.volumes,
        };
      })
      .reduce((acc, cur) => {
        if (!acc.allDates) acc.allDates = [];
        if (!acc.data) acc.data = [];
        acc.allDates = [...acc.allDates, ...cur.dates].filter(
          (el, index, arr) => arr.indexOf(el) === index
        );
        acc.data.push({
          dates: cur.dates,
          volumes: cur.volumes,
        });
        return acc;
      }, {});
    if (dates.data) {
      if (dates.data.length < 2) {
        return false;
      }
    }
    if (dates.allDates) {
      if (sortBy === 'year') {
        dates.allDates = dates.allDates.sort();
      }
      const totalData = dates.allDates.reduce((acc, cur) => {
        const data = dates.data
          .filter(({ dates }) => dates.indexOf(cur) !== -1)
          .map(({ dates, volumes }) => volumes[dates.indexOf(cur)])
          .reduce((acc, cur) => (acc += cur), 0);
        acc.push(data);
        return acc;
      }, []);
      return {
        name: 'Все месторождения',
        consumption: {
          dates: dates.allDates,
          volumes: totalData,
        },
      };
    }
    return false;
  }
);

export const consumptionVolumes = createSelector(
  [getFacilities, allConsumptionVolumes, getSortBy],
  (facilities, all, sortBy) => {
    const formatType = sortBy === 'year' ? 'YYYY' : 'MMMM';
    const data = facilities
      .filter(({ consumption }) => consumption)
      .map(({ name, consumption, id }) => ({
        id,
        name,
        consumption: {
          dates: consumption.dates.map(date => dayjs(date).format(formatType)),
          volumes: consumption.volumes,
        },
      }));
    if (all) data.push(all);
    return data.map(({ name, consumption, id }) => {
      return {
        id,
        name,
        consumption: consumption.dates.map((el, index) => ({
          name: el,
          volumes: consumption.volumes[index],
        })),
      };
    });
  }
);

export const usefulPaths = createSelector(
  [results, getActiveFacilityData],
  ({ useful }, activeFacilityData) => {
    if (!useful) return false;
    if (activeFacilityData && activeFacilityData.path_ids) {
      return activeFacilityData.path_ids;
    }
    return useful.path_ids || [];
  }
);

export const usefulProducers = createSelector(results, ({ useful }) => {
  if (!useful) return false;
  return useful.producer_facility_ids || [];
});

export const usefulStorage = createSelector(results, ({ useful }) => {
  if (!useful) return false;
  return useful.storage_facility_ids || [];
});

export const usefulConsumers = createSelector(results, ({ useful }) => {
  if (!useful) return false;
  return useful.consumer_facility_ids || [];
});

export const cargo = createSelector(results, ({ cargo }) => cargo);

export const transportDemands = createSelector(
  results,
  ({ transport_demands }) => transport_demands
);

export const unitCosts = createSelector(
  results,
  ({ unit_costs }) => unit_costs
);

export const getLoadingLevels = createSelector(
  results,
  ({ loading_levels }) => {
    if (loading_levels) {
      const keys = Object.keys(loading_levels).filter(
        key => !Object.keys(loading_levels[key]).length
      );
      if (keys.length) {
        const obj = Object.assign({}, loading_levels);
        keys.forEach(key => {
          delete obj[key];
        });
        return obj;
      }
    }
    return loading_levels;
  }
);
