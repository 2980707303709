import React from 'react';
import { useSelector } from 'react-redux';
import { getActiveFacility } from '../../../selectors';
import ActiveFacilityContainer from './ActiveFacilityContainer';

export default () => {
  const { type, name } = useSelector(getActiveFacility);
  if (!type) return null;
  return <ActiveFacilityContainer type={type} name={name} />;
};
