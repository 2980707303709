import React from 'react';
import PdfIcon from "./PdfIcon";
import ExcelIcon from "./ExcelIcon";
import RemoveIcon from "./RemoveIcon";
import InitialFiles from './InitialFiles';

export default ({type}) => {
    switch (type) {
        case 'pdf':
            return <PdfIcon/>;
        case 'excel':
            return <ExcelIcon/>;
        case 'initialFiles':
            return <InitialFiles />
        default:
            return <RemoveIcon/>;
    }
}