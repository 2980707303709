import React from 'react';
import TopRow from './TopRow';
import BottomRow from './BottomRow';
import Table from './Table';
import ActiveFacility from './ActiveFacility';

export default () => {
  return (
    <div className='dashboard'>
      <TopRow />
      <ActiveFacility />
      <Table />
      <BottomRow />
    </div>
  );
};
