export default {
    air: {
        stroke: '#583F83',
        strokeWidth: 2
    },
    water: {
        stroke: '#019DDC',
        strokeWidth: 2
    },
    roads: {
        stroke: '#FFA129',
        strokeWidth: 2
    },
    other: {
        stroke: 'hotpink',
        strokeWidth: 2
    },
    railway: {
        stroke: '#6F7A98',
        strokeWidth: 2,
        strokeDasharray: "3 3"
    },
    winter_roads: {
        stroke: 'rgba(21, 182, 234, 0.32)',
        strokeWidth: 2
    }
}