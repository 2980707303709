import React from 'react';

export default React.memo(({ className, ...props }) => {
  return (
    <button
      {...props}
      className={className ? `main-button ${className}` : 'main-button'}
    />
  );
});
