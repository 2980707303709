import React from 'react';
import { useDispatch } from 'react-redux';
import { setActiveFacility } from '../../../actions';

const TopRow = ({ type, name }) => {
  const dispatch = useDispatch();
  const removeActiveFacility = () => dispatch(setActiveFacility({}));
  React.useEffect(() => {
    const title =
      type === 'consumer' ? `потребления «${name}»` : `поступления «${name}»`;
    setTitle(title);
  }, [type, name]);
  const [title, setTitle] = React.useState('');
  return (
    <div className='no-wrap table-top-row'>
      <h2 className='graph__title'>Сводная информация по точке {title}</h2>
      <button className='close-button' onClick={removeActiveFacility}></button>
      <div className='clearfix' />
    </div>
  );
};

export default TopRow;
