import React, { useCallback, useEffect, useState, createRef } from 'react';
import { connect } from 'react-redux';
import { taskId, getWarnings } from '../../../selectors';
import { useDropzone } from 'react-dropzone';
import {
  sendFile,
  setWarning,
  getCommonObjects,
  calculateProject,
} from '../../../actions';
import ModalView from './ModalView';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/Loader';

const validFiles = [
    '.xls',
    '.xlsx',
  '.csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const ModalContainer = ({
  onSendFile,
  history,
  taskId,
  setWarning,
  warnings,
  getCommonObjects,
  calculateProject,
}) => {
  let dropZoneRootRef = createRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [file, setFile] = useState(null);
  const [type, setType] = useState('cargo');
  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles[0]) {
        dropZoneRootRef.current.blur();
        setFile(acceptedFiles[0]);
        setDisabled(false);
        if (warnings) {
          setWarning('');
        }
      }
    },
    [warnings, setWarning, dropZoneRootRef]
  );
  const sendFileHandler = useCallback(() => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append(
      'task',
      JSON.stringify({
        name: 'name',
      })
    );
    const req = onSendFile(formData, type);
    setDisabled(true);
    setShowLoader(true);
    req.catch(() => {
      setShowLoader(false);
    });
  }, [file, onSendFile, type]);
  useEffect(() => {
    const sendOnEnter = ({ keyCode }) => {
      if (keyCode === 13 && file) sendFileHandler();
    };
    if (!disabled) {
      document.addEventListener('keydown', sendOnEnter);
    } else {
      document.removeEventListener('keydown', sendOnEnter);
    }
    return () => {
      document.removeEventListener('keydown', sendOnEnter);
    };
  });
  useEffect(() => {
    if (taskId) {
      if (!taskId.includes('cargo')) {
        getCommonObjects(taskId);
      }
      history.replace(`/dashboard/${taskId}`);
    }
  }, [history, taskId, getCommonObjects]);

  const removeFilesHandler = () => {
    setFile(null);
    setDisabled(true);
    if (warnings) {
      setWarning('');
    }
  };
  const calculateProjectHandler = projectNumber => {
    const req = calculateProject(projectNumber);
    setDisabled(true);
    setShowLoader(true);
    req.catch(() => {
      setShowLoader(false);
    });
  };
  const { getRootProps, getInputProps, rootRef, isDragActive } = useDropzone({
    onDrop,
    accept: validFiles.join(','),
  });
  dropZoneRootRef = rootRef;
  return (
    <>
      <ModalView
        file={file}
        error={warnings}
        disabled={disabled}
        setWarning={setWarning}
        setDisabled={setDisabled}
        setTypeHandler={setType}
        showLoader={showLoader}
        isDragActive={isDragActive}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        sendFileHandler={sendFileHandler}
        calculateProject={calculateProjectHandler}
        removeFilesHandler={removeFilesHandler}
      />
      {showLoader && (
        <div className='firstpage-loader'>
          <Loader loaderText='Идет загрузка...' />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  taskId: taskId(state),
  warnings: getWarnings(state),
});

const mapDispatchToProps = dispatch => ({
  setWarning: err => dispatch(setWarning(err)),
  onSendFile: (file, type) => dispatch(sendFile(file, type)),
  calculateProject: projectNumber => dispatch(calculateProject(projectNumber)),
  getCommonObjects: id => {
    getCommonObjects(id, 'year', dispatch);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
);
