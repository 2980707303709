import React from 'react';

export default function HelpIcon({ legendData }) {
  return (
    <span className='help-container'>
      <span className='help-icon'>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M10.0467 7.50065L9.44666 8.11398C8.96666 8.59398 8.66666 9.00065 8.66666 10.0007H7.33333V9.66732C7.33333 8.92732 7.63333 8.26065 8.11333 7.78065L8.93999 6.94065C9.18666 6.70065 9.33333 6.36732 9.33333 6.00065C9.33333 5.26065 8.73333 4.66732 8 4.66732C7.64637 4.66732 7.30723 4.80779 7.05719 5.05784C6.80714 5.30789 6.66666 5.64703 6.66666 6.00065H5.33333C5.33333 5.29341 5.61428 4.61513 6.11438 4.11503C6.61447 3.61494 7.29275 3.33398 8 3.33398C8.70724 3.33398 9.38552 3.61494 9.88561 4.11503C10.3857 4.61513 10.6667 5.29341 10.6667 6.00065C10.6667 6.58732 10.4267 7.11398 10.0467 7.50065ZM8.66666 12.6673H7.33333V11.334H8.66666V12.6673ZM8 1.33398C7.12452 1.33398 6.25761 1.50642 5.44877 1.84145C4.63993 2.17649 3.90501 2.66755 3.28595 3.28661C2.03571 4.53685 1.33333 6.23254 1.33333 8.00065C1.33333 9.76876 2.03571 11.4645 3.28595 12.7147C3.90501 13.3338 4.63993 13.8248 5.44877 14.1598C6.25761 14.4949 7.12452 14.6673 8 14.6673C9.76811 14.6673 11.4638 13.9649 12.714 12.7147C13.9643 11.4645 14.6667 9.76876 14.6667 8.00065C14.6667 4.31398 11.6667 1.33398 8 1.33398Z'
            fill='#6F7A98'
            fill-opacity='0.4'
          />
        </svg>
      </span>
      {legendData && (
        <span className='help-icon__main-container'>
          <span className='help-icon__triangle'></span>
          {legendData.map((el, key) => {
            return (
              <span className='help-icon__subcontainer relative' key={key}>
                <span className='help-icon__title'>{el.title}</span>
                <span
                  style={{ borderColor: el.color }}
                  className='legend-component__circle'
                />
              </span>
            );
          })}
        </span>
      )}
    </span>
  );
}
