import React from 'react';
import { withRouter } from 'react-router-dom';
import { getFile } from '../../actions/service';
import { saveFile } from '../../utils';
import './cargo.scss';

function Cargo(props) {
  React.useEffect(() => {
    const { params } = props.match.params;
    getFile(params).then(res => {
      saveFile(res, () => {}, 'xlsx', 'Расчитанный грузопоток');
    });
  }, [props.match.params]);
  const onClickHandler = () => {
    const { params } = props.match.params;
    getFile(params).then(res => {
      saveFile(res, () => {}, 'xlsx', 'Расчитанный грузопоток');
    });
  };
  return (
    <div className='modal cargo-modal'>
      <h2 className='cargo-title'>Результат расчета</h2>
      <div onClick={onClickHandler} className='modal-results relative'>
        <div className='modal__dropzone-title-container active'>
          <div className='modal-result-title'>
            <div className='modal__dropzone-title-icon' />
            <h2 className='modal__dropzone-title'>
              Расчитанный грузопоток.xlsx
            </h2>
            <button
              onClick={onClickHandler}
              className='modal__dropzone-remove-button'
            >
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2 15H14V13.3529H2V15ZM14 5.94118H10.5714V1H5.42857V5.94118H2L8 11.7059L14 5.94118Z'
                  fill='#0079C1'
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <h3 className='cargo-modal__sub-title'>
        Если загрузка не началась автоматически — нажмите для загрузки
      </h3>
      <p className='cargo-modal__desc'>
        Заполните полученные результаты расчета необходимой информацией и
        выполните расчет логистического варианта.
      </p>
    </div>
  );
}

export default withRouter(Cargo);
