import React, { useState, useEffect } from 'react';
import CommonBarChart from '../../CommonBarChart';
import HelpIcon from '../../../components/HelpIcon';

const titles = ['Среднегодовая', 'Пиковая'];
const colors = ['#0079C1', '#FAB400'];
const dataKeys = ['average', 'max'];

export default ({ dates, transportDemands }) => {
  const [state, setState] = useState({
    chartData: [],
    dropdownValue: [],
    legendData: [],
  });
  useEffect(() => {
    const dropDownTitles = Object.keys(transportDemands);
    if (dropDownTitles) {
      const dropdownValue = dropDownTitles.map((el, index) => ({
        label: el,
        value: index,
      }));
      setState(prev => ({
        ...prev,
        dropdownValue,
      }));
      const { average, max } = transportDemands[dropDownTitles[0]];
      let cond = false;
      if (average && max) cond = average.length || max.length;
      if (cond) {
        setState(prev => ({
          ...prev,
          legendData: titles.map((title, index) => ({
            title,
            color: colors[index],
          })),
        }));
      }
      let chartData = dates.map(date => ({ name: date }));
      if (cond) {
        chartData = chartData.map((el, index) => ({
          ...el,
          average: average[index],
          max: max[index],
        }));
      }
      setState(prev => ({
        ...prev,
        chartData,
      }));
    }
  }, [dates, transportDemands]);
  const changeActiveValue = ({ label }) => {
    const { average, max } = transportDemands[label];
    let cond = false;
    if (average && max) cond = average.length || max.length;
    if (cond) {
      const chartData = state.chartData.map(({ name }, index) => ({
        name,
        average: average[index],
        max: max[index],
      }));
      setState(prev => ({
        ...prev,
        chartData,
      }));
    }
  };
  let small = false;
  if (dates) {
    small = dates.length < 5 ? true : false;
  }
  return (
    <CommonBarChart
      small={small}
      colors={colors}
      dataKeys={dataKeys}
      chartData={state.chartData}
      legendData={state.legendData}
      dropdownValue={state.dropdownValue}
      showSplash={!state.chartData.length}
      changeActiveValue={changeActiveValue}
      title={
        <>
          Потребность{' '}
          <span className='no-wrap'>
            в транспортных средствах, шт.
            {small && <HelpIcon legendData={state.legendData} />}
          </span>
        </>
      }
    />
  );
};
