import React from 'react';
import './style.css';
import classnames from 'classnames';
import formatNumber from '../utils/formatNumber';

export default function TableBodyComponent({ data, tableDates }) {
  return (
    <>
      <tbody>
        {data.map((el, id) => {
          return (
            <tr className='table-row' key={id}>
              <td className='table-row-names'>
                {el.key.split('@').map((el, key) => {
                  return (
                    <span key={key} className={`table-row-name-${key}`}>
                      {el}
                    </span>
                  );
                })}
              </td>
              {el.values.map((item, key) => {
                return (
                  <td
                    className={classnames('table-row-value', {
                      red: item === 100,
                    })}
                    key={el + key}
                  >
                    {formatNumber(item)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td />
          {tableDates.map((el, key) => {
            return (
              <td className='table-row-date' key={key}>
                {el}
              </td>
            );
          })}
        </tr>
      </tfoot>
    </>
  );
}
