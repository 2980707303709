import React from 'react';

export default function DescriptionTitle({ warnings }) {
  if (warnings.type === 'no_sheet') {
    return (
      <h3 className='alert-desc'>
        Отсутствуют листы:{' '}
        {warnings.missed_sheets.map((el, key) => {
          return (
            <React.Fragment key={key}>
              <span className='alert-desc-medium'>«{el}»</span>
              {key !== warnings.missed_sheets.length - 1 && ', '}
            </React.Fragment>
          );
        })}
        .
      </h3>
    );
  }
  if (warnings.type === 'missed_values') {
    return (
      <>
        <h3 className='alert-subtitle'>Лист «{warnings.sheet_name}»:</h3>
        <h3 className='alert-desc'>
          {warnings.problem}:{' '}
          {warnings.missed_values.map((el, key) => {
            return (
              <React.Fragment key={key}>
                <span className='alert-desc-medium'>{el}</span>
                {key !== warnings.missed_values.length - 1 && ', '}
              </React.Fragment>
            );
          })}
          .
        </h3>
      </>
    );
  }
  if (warnings.type === 'sheets_accordance_error') {
    return (
      <>
        <h3 className='alert-subtitle'>Лист «{warnings.sheet_name}»:</h3>
        <h3 className='alert-desc'>
          Для <span className='alert-desc-medium'>{warnings.for_value}</span>{' '}
          {warnings.problem}:{' '}
          {warnings.missed_values.map((el, key) => {
            return (
              <React.Fragment key={key}>
                <span className='alert-desc-medium'>{el}</span>
                {key !== warnings.missed_values.length - 1 && ', '}
              </React.Fragment>
            );
          })}
          .
        </h3>
      </>
    );
  }
  if (warnings.type === 'handlers_shortage') {
    return (
      <>
        <h3 className='alert-subtitle'>Лист «{warnings.sheet_name}»:</h3>
        <h3 className='alert-desc'>
          {warnings.problem}:{' '}
          {warnings.facilities.map((el, key) => {
            return (
              <React.Fragment key={key}>
                <span className='alert-desc-medium'>{el}</span>
                {key !== warnings.facilities.length - 1 && ', '}
              </React.Fragment>
            );
          })}
          .
        </h3>
      </>
    );
  }
  if (warnings.type === 'resource_shortage') {
    return (
      <>
        <h3 className='alert-subtitle'>Лист «{warnings.sheet_name}»:</h3>
        <h3 className='alert-desc'>
          {warnings.problem}:{' '}
          {warnings.resources.map((el, key) => {
            return (
              <React.Fragment key={key}>
                <span className='alert-desc-medium'>{el}</span>
                {key !== warnings.resources.length - 1 && ', '}
              </React.Fragment>
            );
          })}
          .
        </h3>
      </>
    );
  }
  if (warnings.type === 'incorrect_cell_value') {
    return (
      <>
        <h3 className='alert-subtitle'>Лист «{warnings.sheet_name}»:</h3>
        <h3 className='alert-desc'>
          Ячейка{' '}
          <span className='alert-desc-medium'>
            ({warnings.position.column_letter},{warnings.position.row_number})
          </span>{' '}
          {warnings.problem}
        </h3>
      </>
    );
  }
  if (warnings.type === 'parking_place_miss') {
    return (
      <>
        <h3 className='alert-subtitle'>Лист «{warnings.sheet_name}»:</h3>
        <h3 className='alert-desc'>
          Для потребителей:{' '}
          {warnings.facilities.map((el, key) => {
            return (
              <React.Fragment key={key}>
                <span className='alert-desc-medium'>{el}</span>
                {key !== warnings.facilities.length - 1 && ', '}
              </React.Fragment>
            );
          })}{' '}
          не задан ни один транспорт.
        </h3>
      </>
    );
  }
  return (
    <h3 className='alert-desc'>
      Обратитесь к разработчикам для устранения ошибки.
    </h3>
  );
}
