import {
  SET_PATHS,
  SET_TASK_ID,
  SET_RESULTS,
  CLEAR_STATE,
  SET_FACILITIES,
  SET_WARNING,
  SORT_BY,
  FULL_SCREEN,
  SET_ACTIVE_FACILITY,
} from '../actions/types';

const initialState = {
  sortBy: 'year',
  facilities: [],
  results: [{}],
  activeFacility: {},
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case FULL_SCREEN:
      return {
        ...state,
        fullScreen: action.payload,
      };
    case SET_ACTIVE_FACILITY:
      return {
        ...state,
        activeFacility: action.payload,
      };
    case SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };
    case CLEAR_STATE:
      return initialState;
    case SET_FACILITIES:
      return {
        ...state,
        facilities: action.payload,
      };
    case SET_PATHS:
      return {
        ...state,
        paths: action.payload,
      };
    case SET_RESULTS:
      if (!action.payload) {
        return {
          ...state,
          errors: 'error',
          results: [{}],
        };
      } else {
        return {
          ...state,
          results: action.payload,
        };
      }
    case SET_TASK_ID:
      return {
        ...state,
        taskId: action.payload,
      };
    case SET_WARNING:
      return {
        ...state,
        warnings: action.payload,
      };
    default:
      return state;
  }
}
