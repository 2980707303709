import React from 'react';
import { useSelector } from 'react-redux';
import { facilities } from '../../../selectors';
import formatNumber from '../utils/formatNumber';

const TableComponent = ({ field, el, index, type, rowIndex }) => {
  const getFacilities = useSelector(facilities);
  if (index === 0) {
    const id = field[el];
    const name = getFacilities[id].name;
    if (type === 'consumer') {
      return (
        <>
          <th className='active-facility-table-value'>
            <span
              className={`active-facility-value-color with-circle c-${rowIndex}`}
            >
              {formatNumber(field.percentage, 1)}%
            </span>
          </th>
          <th className={`active-facility-table-value left c-${rowIndex} name`}>
            <span className='active-facility-value'>{name}</span>
          </th>
        </>
      );
    } else {
      return (
        <>
          <th className='active-facility-table-value'>
            <span className='active-facility-color-line-container'>
              <span
                className={`active-facility-color-line c-${rowIndex}`}
                style={{
                  width: `${field.percentage}%`,
                }}
              ></span>
            </span>
            <span className={`active-facility-value-color c-${rowIndex}`}>
              {formatNumber(field.percentage, 1)}%
            </span>
          </th>
          <th className='active-facility-table-value name'>
            <span className='active-facility-value'>{name}</span>
          </th>
        </>
      );
    }
  }
  let value = field[el];
  if (!['producer_id', 'consumer_id', 'arm', 'percentage'].includes(el)) {
    value = value / 1000;
  }
  if (el === 'arm') {
    value = value / 1000000;
  }
  return <th className='active-facility-table-value'>{formatNumber(value)}</th>;
};

export default TableComponent;
