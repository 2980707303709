export const SET_TASK_ID = 'SET_TASK_ID';
export const SET_WARNING = 'SET_WARNING';
export const SET_PATHS = 'SET_PATHS';
export const SET_RESULTS = 'SET_RESULTS';
export const SET_FACILITIES = 'SET_FACILITIES';
export const CALCULATED = 'CALCULATED';
export const CLEAR_STATE = 'CLEAR_STATE';
export const SORT_BY = 'SORT_BY';
export const SET_ACTIVE_FACILITY = 'SET_ACTIVE_FACILITY';
export const FULL_SCREEN = 'FULL_SCREEN';
