import React from 'react';
import Graph from './Graph';
import { Bar } from 'recharts';

export default ({
  changeActiveValue,
  title,
  chartData,
  titles = [],
  colors = [],
  barSize = 8,
  dataKeys = [],
  stackId = false,
  ...props
}) => {
  return (
    <Graph
      {...props}
      title={title}
      data={chartData}
      barSize={barSize}
      changeActiveValue={changeActiveValue}
    >
      {dataKeys.length &&
        chartData.length &&
        dataKeys.map((key, index) => {
          const props = {
            dataKey: key,
            fill: colors[index],
          };
          if (stackId) props.stackId = stackId;
          return <Bar key={key} {...props} />;
        })}
    </Graph>
  );
};
