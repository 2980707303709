import React from 'react';
import Icons from './icons';

const HeaderButton = React.memo(({clickHandler, type, disabled, title}) => {
    const onClickHandler = () => clickHandler(type);
    return <button
        disabled={disabled}
        onClick={onClickHandler}
        className={`header-button ${type}`}
    >
        <span className={`button-icon ${type}`}>
            <Icons type={type}/>
        </span>
        {title}
    </button>
},(prev, next) => {
    return !(prev.type !== next.type || prev.disabled !== next.disabled || prev.title !== next.title);
});

export default HeaderButton;