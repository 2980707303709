import React from 'react';
import { useSelector } from 'react-redux';
import { getWarnings } from '../../../selectors';
import ErrorsView from './ErrorsView';

export default () => {
  const warnings = useSelector(getWarnings);
  if (!warnings) return null;
  return <ErrorsView warnings={warnings} />;
};
