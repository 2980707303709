import React from 'react';
import DescriptionContainer from './DescriptionContainer';
import './style.css';

const firstTitle = {
  warning: 'Расчет не может быть выполнен',
  error: 'Внутренняя ошибка',
};

export default function Errors({ warnings }) {
  const type =
    warnings === 'infeasible' || warnings instanceof Array
      ? 'warning'
      : 'error';
  return (
    <div className='alert-container'>
      <div className={`alert ${type}`}>
        <div className='alert-icon' />
        <div className='alert-text'>
          <h2 className='alert-title'>{firstTitle[type]}</h2>
          <DescriptionContainer type={type} warnings={warnings} />
        </div>
      </div>
    </div>
  );
}
