import React from 'react';
import { useSelector } from 'react-redux';
import { getActiveFacilityData, getFullScreen } from '../../../selectors';
import classnames from 'classnames';
import TopRow from './TopRow';
import TableTopRow from './TableTopRow';
import ActiveFacilityTable from './ActiveFacilityTable';
import ScrollArea from 'react-scrollbar/dist/no-css';

const ActiveFacilityContainer = ({ type, name }) => {
  const { resources } = useSelector(getActiveFacilityData);
  const fullScreen = useSelector(getFullScreen);
  if (!resources) return null;
  const titles = Object.keys(resources);
  // console.log(titles, 'titles');
  // const titles = ['Песок', 'Песок', 'Песок'];
  return (
    <div
      className={classnames('dashboard-card bottom active-facility-table', {
        fullScreen,
      })}
    >
      <ScrollArea speed={1}>
        <TopRow type={type} name={name} />
        <div className='table-container'>
          {titles.map((title, key) => {
            return (
              <div key={key} className='table-subcontainer'>
                {type === 'consumer' && (
                  <TableTopRow
                    type={type}
                    resources={resources}
                    title={title}
                  />
                )}
                <ActiveFacilityTable
                  type={type}
                  title={title}
                  resources={resources}
                />
              </div>
            );
          })}
        </div>
      </ScrollArea>
    </div>
  );
};

export default ActiveFacilityContainer;
