import React, { useState, useEffect } from 'react';
import CommonBarChart from '../../CommonBarChart';
import ScrollArea from 'react-scrollbar/dist/no-css';

export default ({ consumptionVolumes, getActiveFacility }) => {
  const [state, setState] = useState({ chartData: [], dropdownValue: [] });
  useEffect(() => {
    if (consumptionVolumes.length) {
      const dropdownValue = consumptionVolumes.map((el, index) => {
        return {
          label: el.name,
          value: index,
        };
      });
      setState(prevState => ({
        ...prevState,
        chartData: consumptionVolumes[0].consumption.map(
          ({ name, volumes }) => {
            return {
              name,
              volumes: volumes / 1000,
            };
          }
        ),
        dropdownValue,
        defaultValue: dropdownValue[dropdownValue.length - 1],
      }));
    }
  }, [consumptionVolumes]);
  React.useEffect(() => {
    if (getActiveFacility.type === 'consumer') {
      let chartData = [];
      let defaultValue = state.defaultValue;
      for (let index in consumptionVolumes) {
        if (consumptionVolumes[index].id === getActiveFacility.id) {
          chartData = consumptionVolumes[index].consumption.map(
            ({ name, volumes }) => {
              return {
                name,
                volumes: volumes / 1000,
              };
            }
          );
          defaultValue = state.dropdownValue[index];
          break;
        }
      }
      setState(prevState => ({
        ...prevState,
        chartData,
        defaultValue,
      }));
    }
  }, [getActiveFacility.type, getActiveFacility.id]);
  const changeActiveValueHandler = ({ value }) => {
    setState(prevState => ({
      ...prevState,
      chartData: consumptionVolumes[value].consumption.map(
        ({ name, volumes }) => {
          return {
            name,
            volumes: volumes / 1000,
          };
        }
      ),
    }));
  };
  if (!consumptionVolumes.length) return <></>;
  return (
    <div
      className='consumption-graph'
      style={{ height: 400, width: 640, float: 'right' }}
    >
      <ScrollArea speed={1}>
        <CommonBarChart
          height={330}
          barSize={12}
          colors={['#0079C1']}
          dataKeys={['volumes']}
          chartData={state.chartData}
          containerClass='top-row-graph'
          dropdownValue={state.dropdownValue}
          changeActiveValue={changeActiveValueHandler}
          defaultValue={state.defaultValue}
          title='Прогнозная потребность в МТР, тыс. тонн'
          plotName='consumption'
        />
      </ScrollArea>
    </div>
  );
};
