import axios from 'axios';

const config = () => {
    const headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
    };
    return {
        withCredentials: true,
        timeout: 1000*1000,
        headers
    }
};

export default axios.create(config());