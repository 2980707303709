import React from 'react';
import { usefulPaths } from '../../../../selectors';
import { connect } from 'react-redux';
import { Polyline } from 'react-yandex-maps';
import polyStyles from './utils/polyStyles';

const PolylineComponent = ({ pathsData, usefulPaths }) => {
  return (
    <>
      {pathsData.map(({ geometry, class_name, type, id }) => {
        let options = polyStyles(
          type,
          !!usefulPaths && !usefulPaths.includes(id)
        );
        return (
          <Polyline
            key={id}
            options={options}
            geometry={geometry}
            properties={{
              hintContent: `<div class="h-content"><span class="h-content__text">${class_name}</span></div>`,
            }}
          />
        );
      })}
    </>
  );
};

const mapStateToProps = state => ({
  usefulPaths: usefulPaths(state),
});

export default connect(mapStateToProps)(PolylineComponent);
