import React from 'react';
import ConsumptionVolumesView from './ConsumptionVolumesView';

export default ({ consumptionVolumes, getActiveFacility }) => {
  if (!consumptionVolumes.length) return <div className='graph' />;
  return (
    <ConsumptionVolumesView
      getActiveFacility={getActiveFacility}
      consumptionVolumes={consumptionVolumes}
    />
  );
};
