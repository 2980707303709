import React from 'react';

export default {
  consumer: {
    thead: [
      <span>Точка поступения</span>,
      <span>Объем, кт</span>,
      <span>
        <span className='no-wrap'>Плечо</span>{' '}
        <span className='no-wrap'>возки, км</span>
      </span>,
      <span>
        Грузопоток, кт/км
      </span>,
      <span>
        Стоимость <span className='no-wrap'>доставки, тыс. руб.</span>
      </span>,
    ],
    tbody: ['producer_id', 'volume', 'length', 'arm', 'cost'],
  },
  producer: {
    thead: [<span>Точка потребления</span>, <span>Объем, кт</span>],
    tbody: ['consumer_id', 'volume'],
  },
};
