import { connect } from 'react-redux';
import { consumptionVolumes, getActiveFacility } from '../../../../selectors';
import ConsumptionVolumesContainer from './ConsumptionVolumesContainer';

const mapStateToProps = state => ({
  consumptionVolumes: consumptionVolumes(state),
  getActiveFacility: getActiveFacility(state),
});

export default connect(mapStateToProps)(ConsumptionVolumesContainer);
