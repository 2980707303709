import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/array/includes';
import 'core-js/es/string/includes';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './fonts/fonts.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
