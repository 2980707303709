import React from 'react';
import { connect } from 'react-redux';
import { facilities, paths } from '../../../../selectors';
import MapContainer from './MapContainer';

const MapRedux = ({ facilities, paths }) => {
  if (!Object.keys(facilities).length || !paths) return null;
  return <MapContainer paths={paths} facilities={facilities} />;
};

const mapStateToProps = state => ({
  facilities: facilities(state),
  paths: paths(state),
});

export default connect(mapStateToProps)(MapRedux);
