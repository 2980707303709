import React from 'react';
import { Line } from 'recharts';
import Graph from '../../Graph';

const dotStyle = {
  r: 4,
  strokeWidth: 2,
};

const activeDotStyle = {
  r: 6,
  strokeWidth: 5,
  stroke: '#FF8E56',
  fill: 'transparent',
  strokeOpacity: 0.16,
};

export default ({ unitCosts, dates }) => {
  const cond = unitCosts.some(el => el !== 0);
  const chartData = dates.map((date, index) => {
    const obj = {
      name: date,
    };
    if (cond) {
      obj.costs = unitCosts[index] / 1000;
    }
    return obj;
  });
  let small = false;
  if (dates) {
    small = dates.length < 5 ? true : false;
  }
  return (
    <Graph
      small={small}
      data={chartData}
      type='LineChart'
      showSplash={!cond}
      title='Удельные затраты, тыс. руб. за тонну'
    >
      {
        <Line
          dot={dotStyle}
          type='monotone'
          strokeWidth={2}
          dataKey='costs'
          stroke='#FF8E56'
          activeDot={activeDotStyle}
        />
      }
    </Graph>
  );
};
