import React from 'react';
import TableBodyComponent from './TableBodyComponent';
import ScrollArea from 'react-scrollbar/dist/no-css';

export default function Table({ data, tableDates }) {
  const [height, setHeight] = React.useState(100);
  const node = React.useRef();
  const scrollNode = React.useRef();
  React.useEffect(() => {
    setHeight(node.current.clientHeight + 4);
  }, [data.length]);
  return (
    <div
      className='table-container'
      style={{
        height,
      }}
    >
      <ScrollArea ref={scrollNode} speed={1}>
        <table ref={node} className='table'>
          <TableBodyComponent data={data} tableDates={tableDates} />
        </table>
      </ScrollArea>
    </div>
  );
}
