import React from 'react';
import { useSelector } from 'react-redux';
import { getSortBy, getYear } from '../../selectors';
import Dropdown from '../components/Dropdown';
import classnames from 'classnames';
import {
  LineChart,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import formatNumber from './utils/formatNumber';

const margin = { top: 20, left: 30, right: -4, bottom: -5 };

const Components = { LineChart, BarChart };

const cursorStyle = {
  stroke: '#EBECED',
  fill: '#EBECED',
  strokeWidth: 2,
};

const handleSize = len => {
  if (len < 5) {
    return 'small';
  } else if (len < 13) {
    return 'usual';
  } else if (len < 21) {
    return 'big';
  } else if (len < 30) {
    return 'xbig';
  } else {
    return 'large';
  }
};

export default function Graph({
  data,
  legendData,
  showSplash,
  dropdownValue,
  title,
  type = 'BarChart',
  changeActiveValue,
  height = 231,
  width = 630,
  cartesianWidth = 544,
  children,
  containerClass,
  plotName,
  ...props
}) {
  const sortBy = useSelector(getSortBy);
  const year = useSelector(getYear);
  const [tickCount, setTickCount] = React.useState(11);
  React.useEffect(() => {
    if (data.length) {
      setTickCount(data.length);
    }
  }, [data.length]);
  const Component = Components[type];
  const labelFormatterHandler = val => {
    if (sortBy === 'month') {
      return `${val.charAt(0).toUpperCase() + val.substring(1)} ${year}`;
    }
    return `${val} год`;
  };
  const formatterHandler = (value, name, props) => {
    return [
      <>
        <span className='tooltip-circle' style={{ borderColor: props.color }} />
        <span className='tooltip-text'>{formatNumber(value)}</span>
      </>,
    ];
  };
  let cartesianGridProps = {};
  if (showSplash) {
    cartesianGridProps = {
      horizontalPoints: [20, 70, 115, 160, 205],
    };
  }
  let cWidth = width;
  let cCartesianWidth = cartesianWidth;
  let size = handleSize(data.length);
  let componentProps = {};
  let interval = 0;
  if (size === 'small') {
    if (plotName !== 'consumption') {
      cWidth = 340;
      cCartesianWidth = 250;
    }
  }
  if (type === 'BarChart') {
    componentProps.barGap = 2;
  }
  if (['big', 'large'].includes(size)) {
    if (type === 'BarChart') {
      componentProps.barSize = 4;
    }
  }
  if (['big', 'xbig', 'large'].includes(size)) {
    interval = 1;
    if (plotName === 'consumption') {
      componentProps.barSize = 4;
    }
  }
  if (['xbig', 'large'].includes(size) && plotName !== 'consumption') {
    cWidth = 1260;
    cCartesianWidth = 1170;
  }
  return (
    <div className={classnames('graph', [containerClass, size])}>
      {showSplash && <h2 className='splash-title'>Нет данных</h2>}
      <h2 className='graph__title'>{title}</h2>
      {dropdownValue && dropdownValue.length && (
        <Dropdown
          changeActiveValue={changeActiveValue}
          dropdownValue={dropdownValue}
          defaultValue={props.defaultValue}
        />
      )}
      <div className='clearfix' />
      <Component
        {...props}
        data={data}
        width={cWidth}
        margin={margin}
        height={height}
        isAnimationActive={true}
        {...componentProps}
      >
        <CartesianGrid
          stroke='#F2F2F2'
          vertical={false}
          width={cCartesianWidth}
          {...cartesianGridProps}
        />
        <XAxis
          tickMargin={7}
          dataKey='name'
          strokeWidth={1}
          axisLine={false}
          stroke='#A5AEBD'
          tickLine={false}
          interval={interval}
          tickCount={tickCount}
          domain={['dataMin', 'dataMax']}
          padding={{ left: 25, right: 25 }}
        />
        {!showSplash && (
          <YAxis
            axisLine={false}
            stroke='#A5AEBD'
            tickLine={false}
            orientation='right'
          />
        )}
        {!showSplash && (
          <Tooltip
            cursor={cursorStyle}
            animationDuration={200}
            formatter={formatterHandler}
            labelFormatter={labelFormatterHandler}
          />
        )}
        {children}
      </Component>
      {legendData && (
        <div className='chart-legend'>
          {legendData.map(({ title, color }, index) => {
            return (
              <h2 key={index} className='legend-component__title'>
                <span
                  style={{ borderColor: color }}
                  className='legend-component__circle'
                />
                {title}
              </h2>
            );
          })}
        </div>
      )}
    </div>
  );
}
