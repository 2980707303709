import { saveAs } from 'file-saver';

export const saveFile = (response, callback = () => {}, type, name) => {
  let filename = '';
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(response.headers['content-disposition']);
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, '');
    if (name) {
      filename = name + '-' + filename;
    }
  }
  const file = new Blob([response.data], {
    type: response.headers['content-type'],
  });
  saveAs(file, filename || `file.${type}`);
  callback();
};
