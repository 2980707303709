import React from 'react';
import { connect } from 'react-redux';
import {
  usefulProducers,
  usefulStorage,
  usefulConsumers,
  getActiveFacility,
  getActiveFacilityData,
  getActiveFacilities,
} from '../../../../../selectors';
import { setActiveFacility } from '../../../../../actions';
import PlacemarkComponent from './PlacemarkComponent';

const PlacemarkContainer = ({
  mapRef,
  placemarkData,
  usefulProducers,
  usefulStorage,
  activeFacility,
  usefulConsumers,
  setActiveFacility,
  getActiveFacilities,
}) => {
  const onClickHandler = (id, type, name) => {
    if (['producer', 'consumer'].includes(type)) {
      let notActive = false;
      if (type === 'producer') {
        notActive = !!usefulProducers && !usefulProducers.includes(id);
      }
      if (type === 'storage') {
        notActive = !!usefulStorage && !usefulStorage.includes(id);
      }
      if (type === 'consumer') {
        notActive = !!usefulConsumers && !usefulConsumers.includes(id);
      }
      if (!notActive) {
        setActiveFacility({ id, type, name });
      }
    }
  };
  return (
    <>
      {placemarkData.map(({ coordinates, type, name, id }, key) => {
        let notActive = false;
        let active = activeFacility.id === id;
        if (!getActiveFacilities) {
          if (!['crossroad', 'consumer'].type) {
            if (type === 'producer') {
              notActive = !!usefulProducers && !usefulProducers.includes(id);
            }
            if (type === 'storage') {
              notActive = !!usefulStorage && !usefulStorage.includes(id);
            }
            if (type === 'consumer') {
              notActive = !!usefulConsumers && !usefulConsumers.includes(id);
            }
          }
        } else {
          notActive = !getActiveFacilities.includes(id);
        }
        return (
          <PlacemarkComponent
            id={id}
            key={id}
            index={key}
            type={type}
            name={name}
            active={active}
            mapRef={mapRef}
            notActive={notActive}
            coordinates={coordinates}
            clickHandler={onClickHandler}
            placemarkData={placemarkData}
          />
        );
      })}
    </>
  );
};

const mapStateToProps = state => ({
  usefulProducers: usefulProducers(state),
  usefulStorage: usefulStorage(state),
  activeFacility: getActiveFacility(state),
  usefulConsumers: usefulConsumers(state),
  getActiveFacilities: getActiveFacilities(state),
  activeFacilityData: getActiveFacilityData(state),
});

const mapDispathToProps = dispatch => ({
  setActiveFacility: id => dispatch(setActiveFacility(id)),
});

export default connect(mapStateToProps, mapDispathToProps)(PlacemarkContainer);
