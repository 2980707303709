import React from 'react';
import HeaderButton from './HeaderButton';
import Dropdown from '../Dropdown';
import { getInfo, setSortBy } from '../../../actions';
import { taskId } from '../../../selectors';
import { connect } from 'react-redux';

const SortByComponent = ({ getInfo, taskId, setSortBy }) => {
  const data = [
    { value: 'year', label: 'По годам' },
    { value: 'month', label: 'По месяцам' },
  ];
  const onChangeHandler = ({ value }) => {
    setSortBy(value);
    getInfo(taskId, value);
  };
  return (
    <Dropdown
      classContainer='top-row-sort'
      dropdownValue={data}
      changeActiveValue={onChangeHandler}
    />
  );
};

const mapStateToProps = state => ({
  taskId: taskId(state),
});

const mapDispatchToProps = dispatch => ({
  getInfo: (id, sortBy) => dispatch(getInfo(id, sortBy)),
  setSortBy: type => dispatch(setSortBy(type)),
});

const SortByContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SortByComponent);

export default React.memo(
  ({
    clickHandler,
    pdfDisabled,
    excelDisabled,
    initialFilesDisabled,
    cargo,
  }) => {
    return (
      <div className='header-buttons'>
        {!cargo && (
          <>
            <HeaderButton
              type='initialFiles'
              disabled={initialFilesDisabled}
              clickHandler={clickHandler}
              title='Исходные данные'
            />
            <HeaderButton
              type='pdf'
              disabled={pdfDisabled}
              clickHandler={clickHandler}
              title='Экспорт в PDF'
            />
            <HeaderButton
              type='excel'
              disabled={excelDisabled}
              clickHandler={clickHandler}
              title='Экспорт в Excel'
            />
            <SortByContainer />
          </>
        )}
        <HeaderButton
          type='remove'
          clickHandler={clickHandler}
          title='Очистить расчеты'
        />
      </div>
    );
  },
  (prev, next) => {
    return !(
      prev.pdfDisabled !== next.pdfDisabled ||
      prev.excelDisabled !== next.excelDisabled
    );
  }
);
